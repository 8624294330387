import * as React from "react";
import { PropsWithChildren, useEffect } from "react";
import { Container } from "react-bootstrap";
import NovelPagination from "../../../components/novel/pagination";
import PageHelmet from "../../../components/common/helmet";
import PageLoading from "../../../components/common/pageLoading";
import useNovelApi from "../../../hooks/useNovelApi";
import { navigate } from "gatsby";
import NotFoundPage from "../../../pages/404";

interface PropTypes {
  path: string;
  title?: string;
  chapterNumber?: string;
}

const NovelChapterPage: React.FC<PropsWithChildren<PropTypes>> = ({
  title,
  chapterNumber,
}) => {
  const novel = useNovelApi.detail(title);
  const chapter = useNovelApi.chapter(title, chapterNumber);

  useEffect(() => {
    if (novel.error) {
      navigate(`/`);
      return;
    }

    if (chapter.error) {
      navigate(`/novel/${title}`);
      return;
    }
  }, [novel.error, chapter.error]);

  if (!novel.data || !chapter.data) return <PageLoading />;

  if (novel.error || chapter.error) {
    return <NotFoundPage />;
  }
  return (
    <>
      <PageHelmet
        title={`${novel.data.title} chapter: ${parseInt(chapterNumber) + 1}`}
      >
        <style>{`
          body { 
            background-color: #1f2129;
            color: #a9a9a9;
          }
        `}</style>
      </PageHelmet>

      <Container className="my-3">
        <p className="text-center">{novel.data.title}</p>

        <h1 className="text-center">
          Chapter {parseInt(chapterNumber) + 1}:
          <br />
          {chapter.data.title}
        </h1>

        <NovelPagination
          novelData={novel.data}
          chapter={parseInt(chapterNumber)}
        />

        <div>
          {chapter.data?.text?.map((txt, i) => (
            <p
              key={i}
              style={{ textIndent: "2em", overflowWrap: "break-word" }}
            >
              {txt}
            </p>
          ))}
        </div>

        <NovelPagination
          novelData={novel.data}
          chapter={parseInt(chapterNumber)}
        />
      </Container>
    </>
  );
};

export default NovelChapterPage;
