import { ButtonGroup } from "react-bootstrap";
import { Link } from "gatsby";
import * as React from "react";
import { Novel } from "../../models/novel";

interface PropTypes {
  novelData: Novel;
  chapter: number;
}

const NovelPagination: React.FC<PropTypes> = ({ novelData, chapter }) => {
  const novelUrl = `/novel/${novelData.slug}`;

  const prevChapter = chapter - 1;
  const prevUrl = `${novelUrl}/${prevChapter}`;

  const nextChapter = chapter + 1;
  const nextUrl = `${novelUrl}/${nextChapter}`;

  return (
    <>
      <div className="d-grid gap-2 py-5">
        <ButtonGroup>
          <Link
            to={prevUrl}
            className={`btn btn-primary ${prevChapter < 0 ? "disabled" : ""}`}
          >
            Prev
          </Link>
          <Link to={novelUrl} className="btn btn-primary">
            Home
          </Link>
          <Link
            to={nextUrl}
            className={`btn btn-primary ${
              nextChapter > novelData.last_chap ? "disabled" : ""
            }`}
          >
            Next
          </Link>
        </ButtonGroup>
      </div>
    </>
  );
};

export default NovelPagination;
